import axios from "axios";
// import QS from 'qs';
// import store from '../store'
import router from '../router'
import {
  MessageBox
} from 'element-ui'

let cutFlag = false
axios.defaults.timeout = 10000



// 请求拦截
axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.error(error);
  })


// 响应拦截
axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response);
    }
    if (response.status === 500) {
      return Promise.reject("服务器报错");
    }
    if (response.status === 1001) {
      router.push({
        path: '/home'
      })
      return Promise.reject("未知错误");
    }
  }
);

//封装网络请求
let baseURL = ''
// // 设置网络请求的默认地址， （根据环境来替换地址）
// if (process.env.NODE_ENV == 'development') {
//   //本地环境
//   // baseURL = 'http://192.168.0.123:8086';
// } else if (process.env.NODE_ENV == 'production') {
//   //生产环境
//   // baseURL = 'https://api-company.linkcloud360.com';
//   // baseURL = 'https://www.production.com';
// }

/**
 * 封装axios的通用请求
 * @param  {string}   method     get、post....(默认post)
 * @param  {string}   url       请求的接口URL
 * @param  {object}   data      传的参数
 * @param  {object}   options     请求选项,options.accessToken === null 时随机生成字符串作为accessToken
 * @returns {Promise.<TResult>|*}
 */

export const request = ({
  method = 'post',
  url,
  data,
  type,
  res_type,
  options = {}
} = {}) => {
  if (url.indexOf('login') >= 0) {
    console.log(1)
  } else {
    axios.defaults.headers.Authorization = sessionStorage.getItem('api_token')
    // 账号存在多个社区，选择不同社区后，相应接口都需要传社区id
    // let userData = JSON.parse(sessionStorage.getItem('userData')) || store.state.account
    data = {
      ...data
      // community_id: userData.community_id
    }
  }
  //表单提交修改头部
  if (type && type === 'form') {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data'
  }
  //返回值为什么对象
  if (res_type) {
    axios.responseType = res_type
  }
  url = baseURL + `${url}`
  let param1
  let param2
  if (['get', 'head', 'delete', 'jsonp'].indexOf(method.toLocaleLowerCase()) !== -1) {
    options.params = {
      ...data,
      ...options.params
    }
    param1 = {
      ...options,
      body: data
    }
  } else {
    param1 = data
    param2 = options
  }
  // debug('请求：', url, '\n参数：', data)
  return new Promise((resolve) => {
    axios[method](url, param1, param2).then((response) => {
      /* 正式接口使用 */
      // if (response.status === 200) {

      /* mook接口使用 */
      if (response.status === 200) {
        // exitFlag = false
        resolve(response.data)
      }
    }).catch(({
      response
    }) => {
      if (!cutFlag && response) {
        let data = response.data
        let status = response.status
        if (status === 500) {
          let code = data.code
          if (code === 404) {
            MessageBox.alert('找不到系统资源，请稍后再试！', '温馨提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: () => {
                cutFlag = false
              }
            })
          } else {
            MessageBox.alert('服务器异常，请稍后再试！', '温馨提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: () => {
                cutFlag = false
              }
            })
          }
        } else if (status === 504) {
          MessageBox.alert('与服务器断开链接，请稍后再试！', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {
              cutFlag = false
            }
          })
        } else {
          MessageBox.alert('服务器异常，请稍后再试！', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {
              cutFlag = false
            }
          })
        }
      }
      cutFlag = true
    })
  })
}