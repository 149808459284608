import { request } from './config'

let newURL = '';


//上传图片
export const upImg = (data) => request({
    method: 'post',
    url: '/api/upload_photo',
    data: data
})


// 联系我们
export const comtactUs = (data) => request({
    method: 'post',
    url: newURL + '/api/comtact_us',
    data: data
})

//提交问题
export const submitQuestion = (data) => request({
    method: 'post',
    url: newURL + '/api/question_add',
    data: data
})

//搜索问题
export const searchQuestion = (data) => request({
    method: 'post',
    url: newURL + '/api/question_title_search',
    data: data
})

//搜索流水号
export const searchNumber = (data) => request({
    method: 'post',
    url: newURL + '/api/question_no_search',
    data: data
})

//业务咨询
export const information = (data) => request({
    method: 'post',
    url: newURL + '/api/business',
    data: data
})

//问题回复
export const replayQuestion = (data) => request({
    method: 'post',
    url: newURL + '/api/question_reply',
    data: data
})

//问题详情
export const detailQuestions = (data) => request({
    method: 'post',
    url: newURL + '/api/question_detail',
    data: data
})